import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | About Bullseye Marksman Shooting Range
			</title>
			<meta name={"description"} content={"At Bullseye Marksman Shooting Range, our story is defined by a commitment to excellence in the art of marksmanship. "} />
			<meta property={"og:title"} content={"About Us | About Bullseye Marksman Shooting Range"} />
			<meta property={"og:description"} content={"At Bullseye Marksman Shooting Range, our story is defined by a commitment to excellence in the art of marksmanship. "} />
			<meta property={"og:image"} content={"https://vejusrestor.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://vejusrestor.com/img/368084-200.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vejusrestor.com/img/368084-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vejusrestor.com/img/368084-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vejusrestor.com/img/368084-200.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vejusrestor.com/img/368084-200.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vejusrestor.com/img/368084-200.png"} />
			<meta name={"msapplication-TileColor"} content={"https://vejusrestor.com/img/368084-200.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					as="h1"
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline1"
					lg-text-align="center"
					md-text-align="left"
				>
					About Us
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					At Bullseye Marksman Shooting Range, our story is defined by a commitment to excellence in the art of marksmanship. Our range was established to provide a premier destination for individuals passionate about honing their shooting skills in a professional and safe environment.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://vejusrestor.com/img/01.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://vejusrestor.com/img/02.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://vejusrestor.com/img/03.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					We believe that shooting is not just about hitting targets – it's about discipline, precision, and the continual pursuit of perfection. Bullseye Marksman is more than a shooting range – it's a place where skills are refined and respect for the sport is paramount.
				</Text>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Core Features
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					<Strong>
						A Haven for Marksmen
					</Strong>
					{" "}– Our range is designed to cater to shooters of all levels, from beginners to seasoned competitors, offering a variety of shooting experiences.
					<br />
					<br />
					<Strong>
						Advanced Facilities
					</Strong>
					{" "}– Equipped with the latest in shooting range technology, our lanes are suitable for a range of shooting activities, ensuring a fulfilling experience for every marksman.
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-red"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
				>
					Visit Us
				</Link>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://vejusrestor.com/img/04.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="600px"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});